import React from 'react';

import './user-card-style.css';
class UserCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            debug: false,
            isVisibleOverlay: false,
        }
        this.handleMouseOver = this.handleMouseOver.bind(this);
        this.hadleMouseOut = this.hadleMouseOut.bind(this);
    }

    handleMouseOver() {
        this.setState({
            isVisibleOverlay: true
        })
    }

    hadleMouseOut() {
        this.setState({
            isVisibleOverlay: false
        })
    }

    renderSocialLinks(){
        const instagramIcon = require('../../elements/icons/instagram_without_ellipse_icon.svg').default;
        const waIcon = require('../../elements/icons/wa_icon.svg').default;
        let { data } = this.props;

        return(
            <>
                {data.phone && data.phone != ''?(
                        <>
                            <br/>
                            <img  src={waIcon} alt="Wa icon" />&nbsp;<a target='_blank' href={`https://wa.me/${data.phone}`}>Whatsapp</a> 
                        </>
                ):(null)}
         
                {data.instagram && data.instagram != ''?(
                    <>
                        <br/>
                        <img className='SocialIcon' src={instagramIcon} alt="Instagram" />&nbsp;<a target='_blank' href={`https://www.instagram.com/${data.instagram}/`}>{data.instagram}</a> 
                    </>
                ):(null)}
                
            </>
        );

    }

    renderPhone() {
        const phoneIcon = require('../../elements/icons/phone-solid.png');
        let phone = this.props.data.phone;
        if (phone !== '' && phone !== null) {
            return (
                <>
                    <img src={phoneIcon} alt="Phone Icon" />&nbsp;<a href={`tel:${phone}`}>{phone}</a>
                </>
            );
        }
    }

    renderSocialButtons(){
        let {data} = this.props;
        return(
            <div className=''>
                {data.email&& 
                <a className='Link' href={`mailto:${data.email}`} target="_blank">
                    E-Mail
                </a>}

                {data.phone&& 
                <a className='Link' href={`https://wa.me/${data.phone}`} target="_blank">
                    WhatsApp
                </a>}

                {data.instagram&& 
                <a className='Link' href={`https://www.instagram.com/${data.instagram}/`} target="_blank">
                    Instagram
                </a>}

                {data.facebook&& 
                <a className='Link' href={`https://www.facebook.com/${data.facebook}/`} target="_blank">
                    Facebook
                </a>}
            </div>
        )
    }

    renderOverlay() {
        let { data, setIsShortEmail, isShortEmail} = this.props;

        if (this.props.teamCard ) {
            if (this.state.isVisibleOverlay && (data.is_visible_contact == undefined || data.is_visible_contact)) {
                const planetIcon = require('../../elements/icons/planet.png');
                // const letterIcon = require('../../elements/icons/small_letter.png');
         

                let parsedEmail = data.email;
                if (isShortEmail) {
                    parsedEmail = parsedEmail.substring(0, parsedEmail.indexOf('@'))
                } else
                    if (parsedEmail.length >= 26) {
                        parsedEmail = parsedEmail.substring(0, parsedEmail.indexOf('@'))
                        setIsShortEmail(true);
                    }
                return (
                    <div className="UserCardOverlay">
                        <div className="Title" id="TeamTitleCard">
                            {data.name}
                            <br />
                            <span>
                                <img src={planetIcon} alt='Planet Icon' />&nbsp;
                                {data.languages}
                            </span>
                            <div className="Description">
                                {/* <img src={letterIcon} alt='Letter Icon' />&nbsp;<a href={`mailto:${data.email}`}>{parsedEmail}</a>
                                <br />
                                {this.renderPhone()}
                                {this.renderSocialLinks()} */}
                                {this.renderSocialButtons()}
                            </div>
                        </div>
                    </div>
                )
            }
            return (
                <div >
                    <div className="Title">
                        {data.name}
                        <br />
                        <span>
                            {data.title === 'Managing Broker' && data.name === 'David Gueron' ?  data.title : data.languages}
                        </span>
                        <div className="Description">
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div >
                    <div className="Title" >
                        {data.name}
                        <br />
                        <span>
                            {data.title}
                        </span>
                    </div>
                </div>
            );
        }

    }

    renderItem() {
        let { data, dictionary } = this.props;
        let isVisibleOverlay = this.state.isVisibleOverlay;
        const personIcon = require('../../elements/icons/person_b.png');
        
        if (this.props.isLast) {
            return (
                <>
                    <div className="UserCardLast"  onMouseEnter={this.handleMouseOver}  onMouseLeave={this.hadleMouseOut}>
                        <img src={personIcon} alt='Person Icon' />
                        <button className="Button" onClick={this.props.handleModal}>{dictionary.join_the_team}</button>
                    </div>
                </>
            );
        } else {
            if (this.props.teamCard) {
                return (
                    <>
                        <div className="UserCard"  onMouseEnter={this.handleMouseOver}  onMouseLeave={this.hadleMouseOut}>
                            <div className="Overlay" ></div>
                            <img 
                            alt = "Profile" 
                            src = {`https://ngirealty.com/img/profiles/${data.profile_picture}`} 
                            className = {isVisibleOverlay && data.is_visible_contact == undefined && data.is_visible_contact ? "OverlayBlur" : ""} />
                            {this.renderOverlay()}
                        </div>
                    </>
                );
            } else {
                return (
                    <>
                        <div className="UserCard"  onMouseEnter={this.handleMouseOver}  onMouseLeave={this.hadleMouseOut}>
                            <div className="Overlay" ></div>
                            <img alt='Profile' src={`https://ngirealty.com/img/profiles/${data.profile_picture}`} />
                            {this.renderOverlay()}
                        </div>
                    </>
                );
            }

        }
    }

    render() {

        return (
            <div className="UserCardMainDiv">
                    {this.renderItem()}
            </div>

        )
    }

}

export default UserCard;