import React from 'react';
// import Parser from 'html-react-parser';
import ServerApi from '../../services/server-api-service';
import ConfigurationService from '../../services/configuration-service.js';
import ReactPlayer from 'react-player'
import FindMyDealBlock from '../find-my-deal-block/';
import OffersGrid from '../offers-grid';
import './home-page-style.css';
import Neighborhoods from '../neighborhoods';
//videos    
// import DestopVideo from '../../elements/videos/smart_luxe_intro.mp4';
// import MobileVideo from '../../elements/videos/elli_website_bg_mobile.mp4';
import Helmet from 'react-helmet'
import HOne from "../h-one";
import ProjectCardsGrid from '../project-cards-grid';
import InnerArticleGrid from '../inner-article-grid';
import SeoService from '../../services/seo-service';
import { AnimatePresence, motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import ArrayShuffle from '../../functions/array-shuffle/array-shuffle.js'
import { Visibility } from '@mui/icons-material';

// const LOWVIDEO = 'https://ngirealty.com/video/smartluxe/smlx_new_construction_480.mp4';
// const LOWVIDEOMOBILE = 'https://ngirealty.com/video/smlx_new_construction_mobile_480.mp4';

// const HIGHVIDEO = 'https://smartluxe.s3.amazonaws.com/smlx_new_construction_1080.mp4';
const HIGHVIDEO = 'https://smartluxe.s3.amazonaws.com/smart_luxe_intro.mp4';
// const HIGHVIDEOMOBILE = 'https://smartluxe.s3.amazonaws.com/smlx_new_construction_mobile_480.mp4';


class HomePage extends React.Component {
    interval = null;
    serverApi = new ServerApi();
    seoService = new SeoService();
    configurationService = new ConfigurationService();
    constructor(props) {
        super(props);
        this.state = {
            debug: false,
            videoIsReady: false,
            wordCounter: 0,
            isAscent:true,
            wordFlag: false,
            highQualityDownloaded: false,
            randomNumberForLastCardImage: Math.floor(Math.random() * (1 + 1))
        }
    }

    getProjectCards() {
        this.serverApi.getProjectsList('', null)
            .then((data) => {
                let shortRentalCards = [],nonShortRentalCards = [];

                data.data.map((item)=>{
                    if(item.short_rental) shortRentalCards.push(item)
                    else nonShortRentalCards.push(item)
                })
                this.setState({
                    cards: data.data.sort(() => Math.random() - 0.5),
                    shortRentalCards:shortRentalCards,
                    nonShortRentalCards:ArrayShuffle(nonShortRentalCards),
                })

            })
            .catch((error) => {
                console.log(`ERROR: fetch getProjectCards ... -> Component: HomePage ERROR:${error}`);
                // this.props.history.replace({pathname:`/page-not-found`});
            })
    }

    getAllArticles() {
        this.serverApi.getAllArticles('', null)
            .then((data) => {
                let articles = data.data;
                articles.sort((article1, article2) => { return new Date(article2.date) - new Date(article1.date) })
                this.setState({
                    articles: articles
                })

            })
            .catch((error) => {
                console.log(`ERROR: fetch getAllArticles ... -> Component: HomePage ERROR:${error}`);
                // this.props.history.replace({pathname:`/page-not-found`});
            })
    }

    componentWillMount() {
        if (this.props.saveState) this.props.saveState({ currentPage: 'HomePage' })
        this.configurationService.checkLanguage('HomePage');
        if (this.state.debug) {
            console.log('componentWillMount ... -> Component: HomePage');
        }
        this.serverApi.getFeaturedDeals()
            .then((data) => {
                this.setState({
                    featuredDeals: data
                })
            })
            .catch((error) => {
                console.log(`ERROR: fetch getFeaturedDeals ... -> Component: ListingPag  ERROR:${error}`);
            })

        this.getProjectCards();
        this.getAllArticles();
    }

    componentWillUnmount(){
        if(this.state.timerSlogan) clearInterval(this.state.timerSlogan)
    }

    renderSlogan() {
        let { wordCounter } = this.state;
        let { dictionary } = this.props;
        let part_1_words = dictionary.slogan_part_1.split(' ');
        let part_2_words = dictionary.slogan_part_2.split(' ');

        let lang = this.configurationService.getCurrentLanguagePrefix();
        
        const pageTransition = {
            in: {
                opacity: 1
            },
            out: {
                opacity: 0
            },
            exit: {
                opacity: 0
            }
        }

   

        // let sloganPart1Styles = {
        //     marginLeft: '30%'
        // }

        // let sloganPart2Styles = {
        //     marginLeft: '50%'
        // }

        // if(lang != 'en'){
    
        //     sloganPart1Styles = {
        //         marginLeft: '20%'
        //     }
    
        //     sloganPart2Styles = {
        //         marginLeft: '40%'
        //     }
        // }
    

  

        return (
            <div className="Slogan">
                <div className="SloganPart1" style={{visibility:'hidden', position:'relative'}} >
                    {dictionary.slogan_part_1}
                    <div style={{visibility:'visible', position:'absolute'}}>

                  
                    {part_1_words.map((word, index) => {
                        return (
                            <AnimatePresence
                                key={word + index}>
                                {index < wordCounter && (<motion.span

                                    className="SloganWord"
                                    variants={pageTransition}
                                    exit="exit"
                                    animate="in"
                                    initial="out"
                                    transition={{ delay: 1, default: { duration: 0.5 } }}
                                >{word}&nbsp;</motion.span>)}
                            </AnimatePresence>
                        )
                    }
                    )}
                    </div>
                    <br />
                </div>
                <div className="SloganPart2"  style={{visibility:'hidden', position:'relative'}}>
                    {dictionary.slogan_part_2}
                    <div style={{visibility:'visible', position:'absolute'}}>
                    {part_2_words.map((word, index) => {
                        return (
                            <AnimatePresence
                                key={word + index}>
                                {index + part_1_words.length < wordCounter && (<motion.span

                                    variants={pageTransition}
                                    exit="exit"
                                    animate="in"
                                    initial="out"
                                    transition={{ delay: 1, default: { duration: 0.5 } }}
                                >{word}&nbsp;</motion.span>)}
                            </AnimatePresence>
                        )
                    })}
                        
                    </div>
                </div>
            </div>
        )
    }



    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    getVideoPlayer() {
        let { highQualityDownloaded } = this.state;
        let { mobileVerison } = this.props;
        const imgPreload = require('../../elements/images/video_preview.jpg');
        // const imgPreload = require('../../elements/images/video_preview.jpg');
        // const imgPreloadMobile = require('../../elements/images/video_preview_mobile.jpg');
        const imgPreloadMobile = require('../../elements/images/video_preview_mobile_new1.jpg');

        let currentVideo = ''; //https://smartluxe.s3.amazonaws.com/smart_luxe_intro.mp4      https://youtu.be/xW1ajeA91DA
        let currentPreloadImg = imgPreload;

        currentVideo = HIGHVIDEO; //https://smartluxe.s3.amazonaws.com/smart_luxe_intro.mp4      https://youtu.be/xW1ajeA91DA
        currentPreloadImg = imgPreload;
        // if (mobileVerison) {
        //     currentVideo = HIGHVIDEOMOBILE;
        //     currentPreloadImg = imgPreloadMobile;
        // }
        return { currentVideo, currentPreloadImg }
    }

    render() {

      
        let videoUrls = this.getVideoPlayer();
        let currentVideo = videoUrls.currentVideo //https://smartluxe.s3.amazonaws.com/smart_luxe_intro.mp4      https://youtu.be/xW1ajeA91DA
        // let currentVideo = 'https://youtu.be/xW1ajeA91DA' //https://smartluxe.s3.amazonaws.com/smart_luxe_intro.mp4      https://youtu.be/xW1ajeA91DA
        let currentPreloadImg = videoUrls.currentPreloadImg;
        let { dictionary, mobileVerison, hoveredMarkerId } = this.props;
        let { cards, highQualityDownloaded, articles, randomNumberForLastCardImage,shortRentalCards,nonShortRentalCards } = this.state;
        let preloadVideoUrl = HIGHVIDEO;
        // if (mobileVerison) {
        //     preloadVideoUrl = HIGHVIDEOMOBILE
        // }



        let PreloadPlayer = document.getElementById('PreloadPlayer');
        const pageTransition = {
            in: {
                opacity: 1,
                transition: { duration: 0.5 }
            },
            out: {
                opacity: 0,
                transition: { duration: 0.5 }
            }
        }
        //slogan
        // let sloganArray = dictionary.slogan.split(' ');
        // let firstPartSlogan = sloganArray.map((word, index) => {
        //     if (index !== sloganArray.length - 1) {
        //         return (`${word} `);
        //     }
        // })
        // let secondpartSlogan = sloganArray[sloganArray.length - 1];
        //end slogan
        if (this.state.featuredDeals) {
            return (
                <>
                    <motion.div
                        variants={pageTransition}
                        exit="out"
                        animate="in"
                        initial="out"
                    >
                        {/* META TAGS */}
                        {
                            <Helmet>
                                <title>Smart Luxe - {dictionary.slogan}</title>
                                <meta name="description" content={dictionary.deal_finder_desc} />
                                {this.seoService.insertHrefLangTags(
                                    {
                                        path: `/`,
                                    })
                                }
                            

                                <script type="application/ld+json">
                                    {`{
                                        "@context": "https://schema.org",
                                        "@type": "WebSite",
                                        "url": "http://smartluxe.com/",
                                        "potentialAction": {
                                            "@type": "SearchAction",
                                            "target": "http://smartluxe.com/search",
                                            "query": "required"
                                        }
                                        }`
                                    }
                                </script>
                            </Helmet>
                        }

                        {/* META TAGS */}

                        <div className="MainPage Page MenuSpace">
                            <div className="Video">
                                <div className="MainPageVideoDiv">
                                    {/* logo video */}

                                    {this.renderSlogan()}

                                    {/* <div className="Slogan">
                                <div className="SloganPart1">
                                    {dictionary.slogan_part_1}
                                </div>
                                <div className="SloganPart2">
                                    {dictionary.slogan_part_2}
                                </div>
                               
    
                            </div> */}
                                    {/* <div>
                                <div className={this.state.videoIsReady ? "ImgPrealoadVideoDiv" : "ImgPrealoadVideoDiv"}>
                                    <img src={imgPreload} className={this.state.videoIsReady ? "ImgPrealoadVideo" : "ImgPrealoadVideo"} />
                                </div>
                            </div> */}
                                    {/* <div className={"ImgPreloadVideoDiv"}>
                                        <div>



                                        </div>
                                    </div> */}
                                    <div className='player-wrapper PlayerDiv'>
                                        <div className="Location">
                                            MIAMI
                                        </div>
                                        <img  src={currentPreloadImg} alt='preload' className={this.state.videoIsReady ? "Hidden" : "ImgPreloadVideo"} />
                                        <div className={this.state.videoIsReady ? "PlayerBlock" : "Hidden"}>

                                            <ReactPlayer
                                                className={'react-player fixed-bottom MainPageVideo'}
                                                url={currentVideo}
                                                width='100%'
                                                height='100%'
                                                controls={false}
                                                playsinline={true}
                                                muted={true}
                                                loop={true}
                                                playing={true}
                                                config={{
                                                    youtube: {
                                                        playerVars: {
                                                            modestbranding: 1,
                                                            color: 'red'
                                                        }
                                                    },

                                                }}
                                                onReady={() => {
                                                    
                                                    setTimeout(() => {
                                                        this.setState({ videoIsReady: true })

                                                        let word_counts = (dictionary.slogan_part_1.split(' ').concat(dictionary.slogan_part_2.split(' '))).length
                                                        if(!this.state.timerSlogan){
                                                            let timerSlogan = setInterval(() => {
                                                            

                                                                if(this.state.wordCounter <= -1) this.setState({isAscent: true});
                                                                else if(this.state.wordCounter >= word_counts + 3) this.setState({isAscent: false});
                                                                // word_counts + SECONDS DELAY
    
                                                                if(this.state.isAscent){
                                                                    this.setState({wordCounter: ++this.state.wordCounter})
                                                                }else{
                                                                    this.setState({wordCounter: --this.state.wordCounter})
                                                                }
                                                               
                                                            }, 1000);
                                                            
                                                            this.setState({timerSlogan: timerSlogan})
                                                        }
                                                       
                                                    }, 1000)

                                                }}
                                            />
                                        </div>


                                    </div>

                                </div>
                            </div>

                          

                            <div className="ProductBlock FeaturedSmartDeals">
                                <OffersGrid
                                    titleLink={true}
                                    title={dictionary.featured_deals}
                                    data={this.state.featuredDeals}
                                    width={this.props.width}
                                    dictionary={dictionary}
                                    randomNumberForLastCardImage={Boolean(randomNumberForLastCardImage)}
                                />
                            </div>

                            <div className="ProductBlock FeatureProducts">
                                <Link to={'/projects'}>
                                    <HOne
                                        color={'black'}
                                        title={dictionary.featured_projects}
                                    />
                                </Link>
                                <ProjectCardsGrid
                                    cards={nonShortRentalCards}
                                    limit={7}
                                    dictionary={dictionary}
                                    hoveredMarkerId={hoveredMarkerId}
                                    lastCard={true}
                                    randomNumberForLastCardImage={Boolean(randomNumberForLastCardImage)}
                                />
                            </div>


                            <div className="ProductBlock Neighborhoods">
                                <Neighborhoods
                                    dictionary={dictionary}
                                    data={this.props.neighboorhoods}
                                />
                            </div>


                            <div className="ProductBlock MoreArticles">
                                <Link to={'/articles'}>
                                    <HOne title={dictionary.recent_articles}
                                        color={'black'}
                                    />
                                </Link>
                                <InnerArticleGrid
                                    dictionary={dictionary}
                                    data={articles}
                                    limit={3}
                                />
                            </div>

                        </div>
                    </motion.div>
                </>
            );
        } else return null;

    }


}

export default HomePage;